import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';

import stainText from './stain-infinite-just-text.png';
import stainOnly from './stain-infinite-no-text.png';

const scaleBackground = ''; //'https://upload.wikimedia.org/wikipedia/commons/1/15/Cartesian_coordinates_3D.svg';

export default class Slide extends Component {
  state = {
    labelsVisible: false
  }

  componentDidMount() {
    if (this.props.data.slide.index === 1) {
      setTimeout(() => this.setState({ labelsVisible: true }), 1000);
    }
  }

  render() {
    const { data, transition } = this.props;
    const style = transition ? transition.style : {};
    return (
      <div 
        id="slide-root"
        style={{ backgroundImage: this.state.labelsVisible ? `url(${stainOnly})` : `url(${scaleBackground})`}}
      >
        <div
          style={style}
          dangerouslySetInnerHTML={{ __html: data.slide.html }}
        />
        <div
          style={{
            fontSize: 16,
            textAlign: 'right',
            margin: 0,
          }}
        >
          {
            (this.props.data.slide.index !== 1) &&
              <Link to="/6">more </Link>
          }
        </div>
        
        <div 
          className={this.state.labelsVisible ? 'stain-text-opaque' : 'stain-text-transparent'}
          style={{ backgroundImage: `url(${stainText})` }}
        />
      </div>
    );
  }
};

export const query = graphql`
  query SlideQuery($index: Int!) {
    slide(index: { eq: $index }) {
      html
      index
    }
  }
`;
